<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="approvalb">
    <txa></txa>
    <p class="tit">
      <span>审批中心</span>
    </p>
    <div style="height:0.37rem;background:#F4F7FD;"></div>
    <ul class="ul">
      <li
        v-for="(i,index) in text"
        :key="index"
        @click="qiehuan(index)"
        :style="moren==index?'border-bottom:0.02rem solid #9a86db;color:#9a86db;':''"
      >{{i.name}}
        <p v-if="i.num!=0" class="xiaoxi">{{i.num}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import txa from '../components/Txa'
import { query_form_list, query_notify_num } from '../api/api'
export default {
  name: 'approvalb',
  data () {
    return {
      text: [
        {
          name: '发起审批',
          num: '0'
        },
        {
          name: '待我审批的',
          num: '0'
        },
        {
          name: '我已审批的',
          num: '0'
        },
        {
          name: '我发起的',
          num: '0'
        },
        {
          name: '抄送我的',
          num: '0'
        },
        {
          name: '或签共享',
          num: '0'
        }
      ],
      moren: '0',
      path: ''
    }
  },
  components: { txa },
  created () {
    this.path = this.$route.path
    this.luyou()
  },
  mounted () {
    this.jichu()
  },
  watch: {
    $route () {
      this.path = ''
      this.path = this.$route.fullPath
      this.luyou()
    }
  },
  methods: {
    //   路由的跳转
    luyou () {
      if (this.path == '/formyapp') {
        this.moren = '1'
      }
      if (this.path == '/initiateapp') {
        this.moren = '0'
      }
      if (this.path == '/ilaunched') {
        this.moren = '3'
      }
      if (this.path == '/ihaveapp') {
        this.moren = '2'
      }
      if (this.path == '/copyme') {
        this.moren = '4'
      }
      if (this.path == '/orsigntoshare') {
        this.moren = '5'
      }
    },
    qiehuan (index) {
      this.moren = index
      if (index == 1) {
        this.$router.push('/formyapp')
      } else if (index == 0) {
        this.$router.push('/initiateapp')
      } else if (index == 3) {
        this.$router.push('/ilaunched')
      } else if (index == 2) {
        this.$router.push('/ihaveapp')
      } else if (index == 4) {
        this.$router.push('/copyme')
      } else if (index == 5) {
        this.$router.push('/orsigntoshare')
      }
      // 
    },
    jichu () {
      query_notify_num({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          read_flag: '0',
          action: '2'
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.text[1].num = Number(date) > 99 ? '99+' : date
        } else if (res.data.code == 500) {}
      })
      query_notify_num({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          read_flag: '0',
          action: '1'
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.text[3].num = Number(date) > 99 ? '99+' : date
        } else if (res.data.code == 500) {}
      })
      query_notify_num({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          read_flag: '0',
          action: '4'
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.text[4].num = Number(date) > 99 ? '99+' : date
        } else if (res.data.code == 500) {}
      })
      query_notify_num({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          read_flag: '0',
          action: '5'
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.text[5].num = Number(date) > 99 ? '99+' : date
        } else if (res.data.code == 500) {}
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.approvalb {
  background: #fff;
  position: relative;
  .tit {
    height: 0.8rem;
    box-shadow: 0 0.02rem 0.04rem 0 rgba(217, 214, 214, 0.5);
    span {
      display: inline-block;
      width: 2.6rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #9a86db;
      text-align: center;
      font-size: 0.22rem;
      color: #fff;
    }
  }
  .ul {
    display: flex;
    align-items: center;
    border-bottom: 0.01rem solid #cccccc;
    height: 0.84rem;
    li {
      width: 20%;
      height: 0.84rem;
      line-height: 0.84rem;
      text-align: center;
      font-size: 0.14rem;
      color: #4c4a4d;
      cursor: pointer;
      position: relative;
      .xiaoxi{
        position: absolute;
        display: inline-block;
        right: 1.05rem;
        color:#fff;
        top:0.12rem;
        height:0.25rem;
        width:0.25rem;
        text-align: center;
        line-height: 0.25rem;
        background: #f96c6c;
        border-radius: 50%;
      }
    }
  }
}
@media screen and (max-width: 1220px) {
  .approvalb {
    width: 1220px;
  }
}
</style>
